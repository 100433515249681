// Simple justified

import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

const CtaImzaDogrula = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-indigo-900">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-300 sm:text-4xl">
          <span className="block ">{t("vtitle01")}</span>
          {/* <span className="block text-sky-600">{t("vtitle02")}</span> */}
          <span className="block text-xl font-normal">{t("vtitle03")}</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <a href="https://imzadogrula.com" target="_blank" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700">
              {t("vcta")}
            </a>
          </div>
          {/* <div className="ml-3 rounded-md shadow hidden">
            <a href="#" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-sky-600 bg-white hover:bg-sky-50">
              Learn more
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CtaImzaDogrula;
