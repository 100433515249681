/* with_background_image_and_overlapping_cards */
import React from "react";

import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
let posts = [
    {
        title: "e-İmza Kullanmanız İçin 5 Neden",
        href: "https://guide.onaylarim.com/blog/e-imza-kullan%C4%B1n",
        category: { name: "Makale", href: "https://guide.onaylarim.com/blog/e-imza-kullan%C4%B1n" },
        description: "Biliyorsunuz ki dijital dönüşüm sayesinde özellikle pandemiden sonra, fiziksel olarak gerçekleştirdiğimiz birçok işlemi elektronik ortama taşıdık. Bu işlemlerin kapsamına, elektronik ortamda imza atabilmek de dahil oldu....",
        date: "Ocak 13, 2023",
        datetime: "2023-01-13",
        imageUrl: "https://setup.onaylarim.com/websiteresources/blog/e-imza-kullanın.jpg",
        readingTime: "4 dakika",
        author: {
            name: "Bengi O.",
            title: "ONAYLARIM Destek Ekibi",
            imageUrl: "/img/blog/bengi.jpg",
        },
    },
    {
        title: "Yazışmalarınızı KEP ile Güvenli Bir Şekilde Gerçekleştirin",
        href: "https://guide.onaylarim.com/blog/kep-ile-yazisma-yapma",
        category: { name: "Makale", href: "https://guide.onaylarim.com/blog/kep-ile-yazisma-yapma" },
        description: "İş hayatımızda hatta özel hayatımızda bile çoğu yazışmamızı, belge paylaşımlarımızı e-posta üzerinden yapıyoruz. Kullanım kolaylığı, özellikle mobil cihazlardan da erişebiliyor olmamız e-postanın bize en cazip ...",
        date: "Aralık 29, 2022",
        datetime: "2022-12-29",
        imageUrl: "https://setup.onaylarim.com/websiteresources/blog/yazismalarinizi-kep-ile-yapin.jpg",
        readingTime: "4 dakika",
        author: {
            name: "Bengi O.",
            title: "ONAYLARIM Destek Ekibi",
            imageUrl: "/img/blog/bengi.jpg",
        },
    },
    {
        title: "Bankacılık İşlemlerinizi e-İmza ile Gerçekleştirin",
        href: "https://guide.onaylarim.com/blog/sftp-havale-talimat",
        category: { name: "Makale", href: "https://guide.onaylarim.com/blog/sftp-havale-talimat" },
        description: "Hepimiz biliyoruz ki iş hayatımızda ödeme talimatlarını yetkili kişinin imzası olmadan bankalara teslim edemeyiz. Yetkili kişiler de zaten her türlü ödeme sürecinden haberdar olmak ister. Tabii günümüzde çoğu şirket geleneksel ...",
        date: "Aralık 29, 2022",
        datetime: "2022-12-29",
        imageUrl: "https://setup.onaylarim.com/websiteresources/blog/bankacilik-islemleri.jpg",
        readingTime: "3 dakika",
        author: {
            name: "Bengi O.",
            title: "ONAYLARIM Destek Ekibi",
            imageUrl: "/img/blog/bengi.jpg",
        },
    },
    {
        title: "Bilgilerinizin Çalınmasına Karşı Önlem Alıyor Musunuz?",
        href: "https://guide.onaylarim.com/blog/gizlilik-s%C3%B6zle%C5%9Fmesi",
        category: { name: "Makale", href: "https://guide.onaylarim.com/blog/gizlilik-s%C3%B6zle%C5%9Fmesi" },
        description:
            "Bilindiği üzere her şirket için birtakım gizli tutulması gereken bilgiler mevcuttur. İşletmeler müşteri ilişkilerine zarar verebilecek, iş kayıplarına yol açabilecek ve şirketin büyümesini tehdit edebilecek hassas bilgilerin yayılması gibi durumlara...",
        date: "Ocak 5, 2023",
        datetime: "2023-01-05",
        imageUrl: "https://setup.onaylarim.com/websiteresources/blog/bilgileriniz-calinmasin.jpg",
        readingTime: "5 dakika",
        author: {
            name: "Bengi O.",
            title: "ONAYLARIM Destek Ekibi",
            imageUrl: "/img/blog/bengi.jpg",
        },
    },

    {
        title: "Nasıl e-İmza Atılır?",
        href: "https://guide.onaylarim.com/blog/nasil-e-imza-atilir",
        category: { name: "Makale", href: "https://guide.onaylarim.com/blog/nasil-e-imza-atilir" },
        description: "Her geçen gün hayatımıza “e” ile başlayan daha fazla kelime girmeye başladı. e-Fatura, e-defter, e-mühür, e-imza. Bu yazımızda sizlere e-imza atmak için nelere ihtiyaç olduğunu anlatacağız....",
        date: "Ekim 10, 2021",
        datetime: "2021-10-10",
        imageUrl: "https://setup.onaylarim.com/websiteresources/blog/nasil-e-imza-atilir.jpg",
        readingTime: "5 dakika",
        author: {
            name: "İrem G.",
            title: "ONAYLARIM Destek Ekibi",
            imageUrl: "/img/blog/irem.png",
        },
    },
    {
        title: "e-Yazışma Paketi Oluşturun",
        href: "https://guide.onaylarim.com/blog/e-yazisma-olu%C5%9Fturun",
        category: { name: "Makale", href: "https://guide.onaylarim.com/blog/e-yazisma-olu%C5%9Fturun" },
        description:
            "2011 yılında yayınlanan e-Yazışma Projesi ile kamu kurum ve kuruluşları arasındaki resmi yazışmaların elektronik ortamda güvenli bir şekilde yapılmasını sağlanmıştır. Basit bir biçimde açıklamak gerekirse; e-Yazışma Paketi, bir yazı (üst yazı)...",
        date: "Nisan 14, 2022",
        datetime: "2022-04-14",
        imageUrl: "https://setup.onaylarim.com/websiteresources/blog/e-yazisma-paketi-oluşturun.jpg",
        readingTime: "3 dakika",
        author: {
            name: "İrem G.",
            title: "ONAYLARIM Destek Ekibi",
            imageUrl: "/img/blog/irem.png",
        },
    },
];

// import ReactPlayer from "react-player";
{
    /* <ReactPlayer light={Sozlesmeimzalamav2PosterInitial} controls="true" url="https://www.youtube.com/watch?v=ysz5S6PUM-U" />; */
}

const baseurl = "https://guide.onaylarim.com";

const BlogItems = () => {
    const { t } = useTranslation();

    return (
        <div id="blogItems" className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
            <div className="absolute inset-0">
                <div className="bg-white h-1/3 sm:h-2/3" />
            </div>
            <div className="relative max-w-7xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">{t("blogs-title")}</h2>
                    <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">{t("blogs-subTitle")}</p>
                </div>
                <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none  ">
                    {posts.map((post) => (
                        <div key={post.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden group">
                            <div className="flex-shrink-0">
                                <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
                            </div>
                            <div className="flex-1 bg-white group-hover:bg-slate-100 p-6 flex flex-col justify-between">
                                <div className="flex-1">
                                    <p className="text-sm font-medium text-indigo-600">
                                        <a href={post.category.href} className="hover:underline">
                                            {post.category.name}
                                        </a>
                                    </p>
                                    <a href={post.href} className="block mt-2">
                                        <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                                        <p className="mt-3 text-base text-gray-500">{post.description}</p>
                                    </a>
                                </div>
                                <div className="mt-6 flex items-center">
                                    <div className="flex-shrink-0">
                                        <a href={post.author.href}>
                                            <span className="sr-only">{post.author.name}</span>
                                            <img className="h-10 w-10 rounded-full" src={baseurl + post.author.imageUrl} alt="" />
                                        </a>
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-gray-900">{post.author.name}</p>
                                        <div className="flex space-x-1 text-sm text-gray-500">
                                            <time dateTime={post.datetime}>{post.date}</time>
                                            <span aria-hidden="true">&middot;</span>
                                            <span>{post.readingTime}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="max-w-4xl mx-auto mt-10 text-lg text-center">
                    <Trans i18nKey="blogs-all">
                        Sorunuzun cevabını bulamadıysanız{" "}
                        <a href="https://guide.onaylarim.com/blog/" className="text-base font-medium text-sky-700 hover:text-sky-600 whitespace-nowrap">
                            Soru ve Cevaplar
                        </a>{" "}
                        sayfasına göz atabilirsiniz.
                    </Trans>
                </div>
            </div>
        </div>
    );
};
export default BlogItems;
