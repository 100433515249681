/* Split with navbar */

import Animation from "../images/animation_5.mp4";
import AnimationPosterInitial from "../images/animationPosterInitial.png";
import logo from "../images/LogoMain837x158.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

import StartWithUpload from "./startWithUpload";

const Header01 = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-50">
      <div className="relative overflow-hidden">
        <div className="absolute inset-y-0 h-full w-full" aria-hidden="true">
          <div className="relative h-full">
            <svg
              className="absolute right-full transform translate-y-1/3 translate-x-1/4  sm:translate-x-1/2 lg:translate-x-full "
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)"
              />
            </svg>
            <svg
              className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)"
              />
            </svg>
          </div>
        </div>
        <div className="relative pt-6 pb-16 sm:pb-24">
          <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
            <img
              className="w-auto h-14 mx-auto mb-12 hidden md:block xl:hidden"
              src={logo}
              alt="ONAYLARIM Logo"
            />
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block">
                  {t("Tüm belgelerinizi", { ns: "header01" })}
                </span>
                <span className="block text-sky-600">
                  {t("elektronik ortamda imzalayın", { ns: "header01" })}
                </span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                {t("heroSubtitle", { ns: "header01" })}
              </p>
              <div className="flex space-x-4 mt-4 sm:mt-10 mx-auto w-fit">
                <a
                  href="https://app.onaylarim.com/register"
                  className="rounded-md  text-white bg-green-600 hover:bg-green-700 border-gray-200 border px-5 py-3 hidden sm:flex self-center flex-1 shadow cursor-pointer h-36 "
                >
                  <div className="text-left flex flex-col ">
                    <div className="text-lg font-medium">
                      ÜCRETSİZ ÜYE OLUN!
                    </div>
                    <div className="text-sm mt-2 flex-grow text-green-200">
                      Ödeme yapmadan kullanmaya başlayın
                    </div>
                    <div className="mt-5 flex items-center">
                      <div className="text-base font-bold ">Hemen başla</div>
                      <ArrowRightIcon
                        className="ml-2 h-6 w-6 "
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </a>
                <div className="rounded-lg block sm:hidden">
                  <a
                    href="https://app.onaylarim.com/register"
                    className="inline-flex items-center shadow shadow-green-700 justify-center px-5 py-3 border border-transparent text-sm tracking-wider font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                  >
                    {t("cta", { ns: "header01" })}
                  </a>
                </div>
                <StartWithUpload></StartWithUpload>
              </div>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="absolute inset-0 flex flex-col" aria-hidden="true">
            <div className="flex-1" />
            <div className="flex-1 w-full bg-gray-800" />
          </div>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 z-50 pb-10">
            {/* <img className="relative rounded-lg shadow-lg" src="https://tailwindui.com/img/component-images/top-nav-with-multi-column-layout-screenshot.jpg" alt="App screenshot" /> */}
            {/* <img className="relative rounded-lg shadow-lg" loading="lazy" src={MainVideoGif} alt="App screenshot" /> */}

            <video
              muted
              playsInline
              autoPlay
              loop
              poster={AnimationPosterInitial}
              className="block object-contain rounded-lg sm:rounded-xl md:rounded-2xl lg:rounded-3xl  shadow-3xl"
            >
              <source src={Animation} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className="bg-gray-800">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8"></div>
      </div>
    </div>
  );
};

export default Header01;

// export const query = graphql`
//     query ($language: String!) {
//         locales: allLocale(filter: { language: { eq: $language } }) {
//             edges {
//                 node {
//                     ns
//                     data
//                     language
//                 }
//             }
//         }
//     }
// `;
