// grid_with_offset_icons
import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import MyIcon from "./myIcon";

const Features01 = () => {
  const { t } = useTranslation();
  const features = t("features", { returnObjects: true });
  console.log("features", features);

  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-sky-600 uppercase">
          {t("Kullanım alanları")}
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          {t("Kişisel veya kurumsal tüm imza süreçlerinizi")}
          <br /> {t("elektronik ortamda yürütebilirsiniz")}
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          {t("subTitle")}
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-100 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-sky-500 rounded-md shadow-lg">
                        <MyIcon iconName={feature.icon}></MyIcon>
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features01;

// export const query = graphql`
//     query ($language: String!) {
//         locales: allLocale(filter: { language: { eq: $language } }) {
//             edges {
//                 node {
//                     ns
//                     data
//                     language
//                 }
//             }
//         }
//     }
// `;
