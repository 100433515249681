/* with_background_image_and_overlapping_cards */
import React from "react";
import ReactPlayer from "react-player/lazy";

import Sozlesmeimzalamav2PosterInitial from "../videos/sozlesmeimzalamav2.jpg";
import eyazismaPosterInitial from "../videos/eyazisma.png";

import { PlayIcon } from "@heroicons/react/24/solid";

import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
let posts = [
  {
    title: "Ücretsiz e-Yazışma Paketi Oluşturun",
    href: "https://www.youtube.com/watch?v=RhwjS_PYmR0",
    category: {
      name: "Video",
      href: "https://guide.onaylarim.com/blog/kep-ile-yazisma-yapma",
    },
    description:
      "İş hayatımızda hatta özel hayatımızda bile çoğu yazışmamızı, belge paylaşımlarımızı e-posta üzerinden yapıyoruz. Kullanım kolaylığı, özellikle mobil cihazlardan da erişebiliyor olmamız e-postanın bize en cazip ...",
    date: "Aralık 29, 2022",
    datetime: "2022-12-29",
    imageUrl: Sozlesmeimzalamav2PosterInitial,
    readingTime: "4 dakika",
    author: {
      name: "Bengi O.",
      title: "ONAYLARIM Destek Ekibi",
      imageUrl: "/img/blog/bengi.jpg",
    },
  },
  {
    title: "Sözleşmelerinizi Yasal Geçerli e-İmza ile İmzalayın",
    href: "https://youtu.be/goJNQs_SvHE",
    category: {
      name: "Video",
      href: "https://guide.onaylarim.com/blog/kep-ile-yazisma-yapma",
    },
    description:
      "İş hayatımızda hatta özel hayatımızda bile çoğu yazışmamızı, belge paylaşımlarımızı e-posta üzerinden yapıyoruz. Kullanım kolaylığı, özellikle mobil cihazlardan da erişebiliyor olmamız e-postanın bize en cazip ...",
    date: "Aralık 29, 2022",
    datetime: "2022-12-29",
    imageUrl: eyazismaPosterInitial,
    readingTime: "4 dakika",
    author: {
      name: "Bengi O.",
      title: "ONAYLARIM Destek Ekibi",
      imageUrl: "/img/blog/bengi.jpg",
    },
  },
];

const baseurl = "https://guide.onaylarim.com";

const controls = true;
const VideoPosts = () => {
  const { t } = useTranslation();

  return (
    <div
      id="videoPosts"
      className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
    >
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            {t("videos-title")}
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            {t("videos-subTitle")}
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post) => (
            <div
              key={post.title}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-shrink-0 h-60">
                {/* <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" /> */}
                <ReactPlayer
                  className="w-full object-cover"
                  width="100%"
                  height="100%"
                  light={post.imageUrl}
                  controls={controls}
                  url={post.href}
                  playIcon={
                    <div className="bg-white p-0.5 rounded-full shadow-xl hover:bg-sky-200 group">
                      <PlayIcon className="h-20 w-20 text-sky-600 group-hover:text-sky-700 "></PlayIcon>
                    </div>
                  }
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-600">
                    <span>{post.category.name}</span>
                  </p>
                  <a href={post.href} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">
                      {post.title}
                    </p>
                  </a>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href={post.author.href}>
                      <span className="sr-only">{post.author.name}</span>
                      <img
                        className="h-10 w-10 rounded-full"
                        src={baseurl + post.author.imageUrl}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      {post.author.name}
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.datetime}>{post.date}</time>
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.readingTime}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="max-w-4xl mx-auto mt-10 text-lg text-center">
                    <Trans i18nKey="videos-all">
                        Sorunuzun cevabını bulamadıysanız{" "}
                        <a href="https://guide.onaylarim.com/blog/" className="text-base font-medium text-sky-700 hover:text-sky-600 whitespace-nowrap">
                            Soru ve Cevaplar
                        </a>{" "}
                        sayfasına göz atabilirsiniz.
                    </Trans>
                </div> */}
      </div>
    </div>
  );
};
export default VideoPosts;
