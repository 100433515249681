import * as React from "react";
import * as hicons from "@heroicons/react/24/solid";

const MyIcon = (props) => {
  console.log("props", props);
  const { ...icons } = hicons;
  const TheIcon = icons[props.iconName];
  console.log("TheIcon", TheIcon);
  return (
    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-sky-500 text-white">
      {/* <props.iconName className="h-6 w-6" aria-hidden="true" /> */}
      {TheIcon ? (
        <TheIcon className="h-6 w-6" aria-hidden="true" />
      ) : (
        <div>{props.iconName}</div>
      )}
    </div>
  );
};

export default MyIcon;
