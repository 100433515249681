import { Component } from "react";
// import axios from "Axios";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import React from "react";

class StartWithUpload extends Component {
  constructor(props) {
    super(props);
    this.state = { anonymousSessionId: "", message: "", uploadProgress: 0 };
    this.submitFile = this.submitFile.bind(this);
  }

  // componentDidMount2() {
  //     axios
  //         .get(process.env.GATSBY_API_URL + "onboarding/getAnonymousSessionId")
  //         .then((e) => {
  //             if (e.data.toString().length === 42) {
  //                 this.setState({ anonymousSessionId: e.data });
  //             }
  //         })
  //         .catch((error) => {
  //             console.log(error);
  //         });
  // }
  componentDidMount() {
    fetch(process.env.GATSBY_API_URL + "onboarding/getAnonymousSessionId")
      .then((response) => response.text())
      .then((id) => {
        if (id.toString().length === 42) {
          this.setState({ anonymousSessionId: id });
        } else {
          console.log("getAnonymousSessionId error 1", id);
        }
      })
      .catch((error) => {
        console.log("getAnonymousSessionId error", error);
      });
  }
  // submitFile2() {
  //     const anonymoussessionid = this.state.anonymousSessionId;
  //     const formData = new FormData();
  //     const fileElement = document.getElementById("myFile");
  //     if (fileElement && fileElement.files && fileElement.files.length > 0) {
  //         formData.append("file", fileElement.files[0]);
  //         const headers = { "Content-Type": "multipart/form-data", anonymoussessionid: anonymoussessionid };
  //         axios
  //             .post(process.env.GATSBY_API_URL + "Onboarding/upload", formData, {
  //                 headers,
  //                 onUploadProgress: (progressEvent) => {
  //                     this.setState({ uploadProgress: Math.round((progressEvent.loaded / progressEvent.total) * 100) });
  //                 },
  //             })
  //             .then((e) => {
  //                 if (e.data.error !== null) {
  //                     this.setState({ message: "Hata. " + e.data.error });
  //                 } else {
  //                     // const anonymousUploadResult = e.data.result;
  //                     window.location.href = process.env.ONBOARDING_URL + "templateDesigner/" + anonymoussessionid;
  //                 }
  //             })
  //             .catch((error) => {
  //                 this.setState({ message: "Hata. " + error.toString() });
  //             })
  //             .finally(() => {
  //                 //
  //             });
  //     }
  // }
  submitFile() {
    const anonymoussessionid = this.state.anonymousSessionId;
    const formData = new FormData();
    const fileElement = document.getElementById("myFile");
    if (fileElement && fileElement.files && fileElement.files.length > 0) {
      this.setState({ message: "Lütfen bekleyin." });
      formData.append("file", fileElement.files[0]);
      const headers = { anonymoussessionid: anonymoussessionid };
      fetch(process.env.GATSBY_API_URL + "Onboarding/upload", {
        method: "POST",
        headers: headers,
        body: formData,
      })
        .then((response) => response.json())
        .then((e) => {
          if (e.error !== null) {
            this.setState({ message: "Hata. " + e.error });
          } else {
            window.location.href =
              process.env.ONBOARDING_URL +
              "templateDesigner/" +
              anonymoussessionid;
          }
        })
        .catch((error) => {
          this.setState({ message: "Hata. " + error.toString() });
        })
        .finally(() => {
          //
        });
    }
  }
  renderInvisible() {
    return <div></div>;
  }
  renderVisible() {
    return (
      <label className="rounded-md  bg-white hover:bg-green-50 border-gray-200 border px-5 py-3 hidden md:flex self-center flex-1 shadow cursor-pointer h-36">
        <div className="text-left flex flex-col text-green-600">
          <div className="text-lg font-medium">BELGENİZE İMZA ATIN!</div>
          <div className="text-sm mt-2 flex-grow text-green-800">
            Birkaç tıklama ile belgenizi imzalayın
          </div>
          <div
            className={
              " flex items-center" +
              (this.state.message || this.state.uploadProgress > 0
                ? "mt-2"
                : "mt-5")
            }
          >
            <div className="text-base font-bold ">Dosya seçin</div>
            <ArrowRightIcon className="ml-2 h-6 w-6 " aria-hidden="true" />
          </div>
          {this.state.message && (
            <div className="text-xs  flex-grow text-red-800">
              {this.state.message}
            </div>
          )}
          {this.state.uploadProgress > 0 && (
            <div className="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700">
              <div
                className="bg-green-600 h-2 rounded-full"
                style={{ width: this.state.uploadProgress + "%" }}
              ></div>
            </div>
          )}
        </div>
        <input
          id="myFile"
          type="file"
          onChange={this.submitFile}
          className="sr-only"
          accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessing,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.ms-word.document.macroEnabled.12,application/vnd.ms-word.template.macroEnabled.12,application/rtf,application/vnd.oasis.opendocument.text,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml,image/tiff,application/pdf,image/png,image/jpg,image/jpeg"
        />
      </label>
    );
  }
  render() {
    if (
      this.state.anonymousSessionId &&
      this.state.anonymousSessionId.length === 42
    ) {
      return this.renderVisible();
    } else {
      return this.renderInvisible();
    }
  }
}

export default StartWithUpload;
