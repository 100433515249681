/* with_background_image_and_overlapping_cards */
import * as React from "react";
import {
  PhoneIcon,
  LifebuoyIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { LightBulbIcon } from "@heroicons/react/24/solid";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white">
      {/* Header */}
      <div className="relative pb-32 bg-gray-100">
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-gray-700 md:text-5xl lg:text-6xl">
            {t("title")}
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-gray-500">
            {t("subTitle")}
          </p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Contact us
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          <div className="flex flex-col bg-white rounded-2xl shadow-xl">
            <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
              <div className="absolute top-0 p-5 inline-block bg-sky-600 rounded-xl shadow-lg transform -translate-y-1/2">
                <LifebuoyIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </div>
              <h3 className="text-xl font-medium text-gray-900">
                {t("technicalTitle")}
              </h3>
              <p className="mt-4 text-base text-gray-500">
                {t("technicalLine1")}
              </p>
              <p className="mt-1 text-base text-gray-500">
                {t("technicalLine2")}
              </p>
            </div>
            <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
              <a
                href="https://guide.onaylarim.com/docs/intro"
                target="_blank"
                rel="noreferrer"
                className="text-base font-medium text-sky-700 hover:text-sky-600"
              >
                {t("technicalLink")}

                <ArrowTopRightOnSquareIcon
                  className="ml-0.5 mb-1 inline h-4 w-4"
                  aria-hidden="true"
                />
                {/* <span aria-hidden="true"> &rarr;</span> */}
              </a>
            </div>
          </div>
          <div className="flex flex-col bg-white rounded-2xl shadow-xl">
            <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
              <div className="absolute top-0 p-5 inline-block bg-sky-600 rounded-xl shadow-lg transform -translate-y-1/2">
                <LightBulbIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </div>
              <h3 className="text-xl font-medium text-gray-900">
                {t("feedbackTitle")}
              </h3>
              <p className="mt-4 text-base text-gray-500">
                {t("feedbackLine1")}
              </p>
              <p className="mt-1 text-base text-gray-500">
                {t("feedbackLine2")}
              </p>
            </div>
            <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
              <a
                href="https://onaylarim.nolt.io/"
                target="_blank"
                rel="noreferrer"
                className="text-base font-medium text-sky-700 hover:text-sky-600"
              >
                {t("feedbackLink")}
                <ArrowTopRightOnSquareIcon
                  className="ml-0.5 mb-1 inline h-4 w-4"
                  aria-hidden="true"
                />
                {/* <span aria-hidden="true"> &rarr;</span> */}
              </a>
            </div>
          </div>
          <div className="flex flex-col bg-white rounded-2xl shadow-xl">
            <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
              <div className="absolute top-0 p-5 inline-block bg-sky-600 rounded-xl shadow-lg transform -translate-y-1/2">
                <PhoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <h3 className="text-xl font-medium text-gray-900">
                {t("salesTitle")}
              </h3>
              <p className="mt-4 text-base text-gray-500">{t("salesLine1")}</p>
              <p className="mt-1 text-base text-gray-500">
                <Trans i18nKey="salesLine2">
                  Sorularınızı sağ alttaki canlı sohbet ile iletebileceğiniz
                  gibi dilerseniz bize{" "}
                  <span className="whitespace-nowrap">0 312 265 09 15</span>'den
                  de ulaşabilirsiniz.
                </Trans>
              </p>
            </div>
            <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
              <span className="text-base font-medium text-transparent">C</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Contact;
