import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Header01 from "../components/header01";
import Features01 from "../components/features01";
import FeaturesFree from "../components/featuresFree";
import References from "../components/references";
import FaqFree from "../components/faqFree";

import PricingPlansNew from "../components/pricingPlansNew";
// import Pricing from "../components/pricing";
import Steps from "../components/steps";
import Contact from "../components/contact";
import BottomBanner from "../components/bottomBanner";
import Stats from "../components/stats";

import { graphql } from "gatsby";
import CtaFree from "../components/ctaFree";
import CtaImzaDogrula from "../components/ctaImzaDogrula";
import BlogItems from "../components/blogItems";
import VideoPosts from "../components/videoPosts";
// import PricingPlansLatest from "../components/pricingPlansLatest";

const seoData = {
  title: "ONAYLARIM",
  description: "Tüm belgelerinizi elektronik ortamda imzalayın.",
  slug: "",
};

const IndexPage = () => {
  return (
    <Layout pageTitle="Home Page">
      <Seo title={seoData.title} description={seoData.description || seoData.excerpt || "nothin’"} pathname={seoData.slug} article />

      <Header01></Header01>

      <Features01></Features01>
      {/* <Features></Features> */}
      <FeaturesFree></FeaturesFree>
      <References></References>
      <Steps></Steps>

      {/* <Pricing></Pricing> */}
      {/* <PricingPlans></PricingPlans> */}
      {/* <PricingPlansOnlyFree></PricingPlansOnlyFree> */}
      <PricingPlansNew></PricingPlansNew>
      {/* <PricingPlansLatest></PricingPlansLatest> */}
      {/* <Cta></Cta> */}
      <Stats></Stats>
      <CtaFree></CtaFree>
      {/* <Faq></Faq> */}
      <FaqFree></FaqFree>
      <CtaImzaDogrula></CtaImzaDogrula>
      <BlogItems></BlogItems>
      <VideoPosts></VideoPosts>
      <Contact></Contact>

      <BottomBanner></BottomBanner>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

// export const query = graphql`
//     query ($language: String!) {
//         locales: allLocale(filter: { ns: { in: ["common"] }, language: { eq: $language } }) {
//             edges {
//                 node {
//                     ns
//                     data
//                     language
//                 }
//             }
//         }
//     }
// `;
