import * as React from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import { NoSymbolIcon, PlusCircleIcon } from "@heroicons/react/24/outline";

const PricingPlansNew = () => {
  const { t } = useTranslation();
  const tiers = t("new_tiers", { returnObjects: true });
  console.log("tiers", tiers);
  return (
    <div id="pricingPlans" className="bg-white relative">
      <div className="absolute inset-0 h-1/2 bg-gray-100" />
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
            <Trans i18nKey="newTamamen">
              Tamamen{" "}
              <span className="underline decoration-pink-500 decoration-solid decoration-4">
                ücretsiz
              </span>{" "}
              olarak kullanabilirsiniz!
            </Trans>
          </h1>
          <p className="mt-5 text-lg text-gray-500 sm:text-center">
            {t("newh1_0")}
          </p>
          <p className="mt-0 text-lg text-gray-500 sm:text-center">
            {t("h1_1")}
          </p>
          {/* <p className="mt-5 text-lg text-gray-500 sm:text-center">
                        {t("h1_2")}
                        <br />
                        {t("h1_3")}
                    </p> */}
        </div>

        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:gap-4 lg:max-w-4xl sm:mx-auto sm:grid-cols-3 xl:grid-cols-3">
          {tiers.map((tier) =>
            tier.isPrimary ? (
              <React.Fragment key={tier.id}>
                <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 bg-white ">
                  <div className="p-6">
                    <h2 className="text-sm leading-6 font-medium text-sky-600">
                      {tier.isEnterprise ? "Kurumsal" : "Kişisel"}
                    </h2>
                    <h2 className="text-lg leading-6 font-medium text-gray-900 underline decoration-sky-600 decoration-4">
                      {tier.name}
                    </h2>
                    <p className="mt-4 text-sm text-gray-500">
                      {tier.description}
                    </p>
                    <p
                      className={
                        tier.priceMonthly >= 0 ? "mt-8" : "mt-8 hidden"
                      }
                    >
                      <span className="text-4xl font-extrabold text-gray-900">
                        {tier.priceMonthly} TL
                      </span>{" "}
                      <span className="text-base font-medium text-gray-500">
                        {tier.priceType && "/"}
                        {tier.priceType}
                      </span>
                    </p>
                    <a
                      href={tier.href}
                      className="mt-8 block w-full bg-sky-600 border border-sky-600 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-sky-700"
                    >
                      {tier.cta}
                    </a>
                  </div>
                  <div className="pt-6 pb-8 px-6">
                    <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                      Özellikler
                    </h3>
                    <ul className="mt-6 space-y-2">
                      {tier.includedFeatures.map((feature, index) => (
                        <li key={index} className="flex space-x-3">
                          {feature.valueValue === 1 ? (
                            <CheckIcon
                              className="flex-shrink-0 h-5 w-5 text-green-500"
                              aria-hidden="true"
                            />
                          ) : feature.valueValue === 2 ? (
                            <PlusCircleIcon
                              className="flex-shrink-0 h-5 w-5 text-slate-500 opacity-60"
                              aria-hidden="true"
                            />
                          ) : feature.valueValue === 0 ? (
                            <NoSymbolIcon
                              className="flex-shrink-0 h-5 w-5 text-red-300 opacity-60"
                              aria-hidden="true"
                            />
                          ) : (
                            <NoSymbolIcon
                              className="flex-shrink-0 h-5 w-5 text-red-500 opacity-0"
                              aria-hidden="true"
                            />
                          )}
                          <span className="text-sm text-gray-500">
                            {feature.nameValue}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment key={tier.id}>
                <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 bg-white ">
                  <div className="p-6">
                    <h2 className="text-sm leading-6 font-medium text-gray-600">
                      {tier.isEnterprise ? "Kurumsal" : "Kişisel"}
                    </h2>
                    <h2 className="text-lg leading-6 font-medium text-gray-900 underline decoration-gray-600 decoration-4">
                      {tier.name}
                    </h2>
                    <p className="mt-4 text-sm text-gray-500">
                      {tier.description}
                    </p>
                    <p
                      className={
                        tier.priceMonthly >= 0 ? "mt-8" : "mt-8 invisible"
                      }
                    >
                      <span className="text-4xl font-extrabold text-gray-900">
                        {tier.priceMonthly} TL
                      </span>{" "}
                      <span className="text-base font-medium text-gray-500">
                        {tier.priceType && "/"}
                        {tier.priceType}
                      </span>
                    </p>
                    <a
                      href={tier.href}
                      className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                    >
                      {tier.cta}
                    </a>
                  </div>
                  <div className="pt-6 pb-8 px-6">
                    <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                      Özellikler
                    </h3>
                    <ul className="mt-6 space-y-2">
                      {tier.includedFeatures.map((feature, index) => (
                        <li key={index} className="flex space-x-3">
                          {feature.valueValue === 1 ? (
                            <CheckIcon
                              className="flex-shrink-0 h-5 w-5 text-green-500"
                              aria-hidden="true"
                            />
                          ) : feature.valueValue === 2 ? (
                            <PlusCircleIcon
                              className="flex-shrink-0 h-5 w-5 text-slate-500 opacity-60"
                              aria-hidden="true"
                            />
                          ) : feature.valueValue === 0 ? (
                            <NoSymbolIcon
                              className="flex-shrink-0 h-5 w-5 text-red-300 opacity-60"
                              aria-hidden="true"
                            />
                          ) : (
                            <NoSymbolIcon
                              className="flex-shrink-0 h-5 w-5 text-red-500 opacity-0"
                              aria-hidden="true"
                            />
                          )}
                          <span className="text-sm text-gray-500">
                            {feature.nameValue}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </React.Fragment>
            )
          )}
        </div>
        {/* <div className="max-w-4xl mx-auto mt-10 text-lg text-center">
                    <Trans i18nKey="details">
                        Belgelerinizin güvenli bulutta saklanmasını sağlayan diğer planlar için{" "}
                        <a href="/extendedPricingPlans" className="underline text-sky-700 hover:text-sky-600 whitespace-nowrap">
                            tüm planlar
                        </a>{" "}
                        sayfasına göz atabilirsiniz.
                    </Trans>
                </div> */}
        <div className="max-w-4xl mx-auto mt-5 text-lg text-center flex flex-col space-y-2">
          <div className="flex items-center space-x-1.5">
            <CheckIcon
              className="flex-shrink-0 h-5 w-5 text-green-500"
              aria-hidden="true"
            />
            <div className="text-sm text-gray-500">Var.</div>
          </div>
          <div className="flex items-center space-x-1.5">
            <NoSymbolIcon
              className="flex-shrink-0 h-5 w-5 text-red-300 opacity-60"
              aria-hidden="true"
            />
            <div className="text-sm text-gray-500">Yok.</div>
          </div>
          <div className="flex items-center space-x-1.5">
            <PlusCircleIcon
              className="flex-shrink-0 h-5 w-5 text-slate-500 opacity-60"
              aria-hidden="true"
            />
            <div className="text-sm text-gray-500">
              Eklenti olarak satın alınabilir.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPlansNew;

// export const query = graphql`
//     query ($language: String!) {
//         locales: allLocale(filter: { ns: { in: ["pricingPlansNew"] }, language: { eq: $language } }) {
//             edges {
//                 node {
//                     ns
//                     data
//                     language
//                 }
//             }
//         }
//     }
// `;
