// centered_accordion

/* This example requires Tailwind CSS v2.0+ */
import * as React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const FaqFree = () => {
  const { t } = useTranslation();
  const faqs = t("faqs", { returnObjects: true });
  return (
    <div id="faq" className="bg-gray-200">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto divide-y divide-gray-400">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">{t("faq")}</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-400">
            {faqs.map((faq) => (
              <Disclosure key={faq.id} as="div" className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon className={classNames(open ? "-rotate-180" : "rotate-0", "h-6 w-6 transform")} aria-hidden="true" />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-600 space-y-6">
                        {faq.answer.split("~").map((str, index) => (
                          <p key="index">{str}</p>
                        ))}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
        <div className="max-w-4xl mx-auto mt-10 text-lg text-center">
          <Trans i18nKey="detailsForFaq">
            Sorunuzun cevabını bulamadıysanız{" "}
            <a href="/extendedFaq" className="text-base font-medium text-sky-700 hover:text-sky-600 whitespace-nowrap">
              Soru ve Cevaplar
            </a>{" "}
            sayfasına göz atabilirsiniz.
          </Trans>
        </div>
      </div>
    </div>
  );
};

export default FaqFree;
